<template>
  <div class="modalhistory">
    <a-modal
      title="Status Pengajuan"
      v-model="modals"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      width="781px"
      :footer="null"
    >
      <a-skeleton :loading="loading">
        <div class="contentModalHistory">
          <a-row :gutter="[5, 5]">
            <a-col :lg="12">
              <div class="historytable">
                <table>
                  <thead>
                    <tr>
                      <td colspan="2">
                        <span>Nomor</span>
                        <p>{{ claim.no_claim }}</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>Produk</span>
                        <p>{{ claim.produk }}</p>
                      </td>
                      <td>
                        <span>Produk</span>
                        <p>{{ claim.produk_detail }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <span
                          >Status
                          <a-button type="link" @click="ModalStatus"
                            ><a-icon type="info-circle" /></a-button
                        ></span>
                        <p
                          v-if="claim.status_claim == 'Menunggu Verifikasi'"
                          style="color: rgb(0, 196, 196)"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="claim.status_claim == 'Hold'"
                          style="color: #333"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="claim.status_claim == 'Pending'"
                          style="color: #ee5843"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="
                            claim.status_claim ==
                            'Mohon Lengkapi Kekurangan Dokumen'
                          "
                          style="color: #ff9900"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="
                            claim.status_claim ==
                            'Sedang di-review oleh Asuransi'
                          "
                          style="color: #4c88ff"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="
                            claim.status_claim == 'Proses Banding ke Asuransi'
                          "
                          style="color: #6464ff"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="claim.status_claim == 'Tidak Dicover'"
                          style="color: #ea9999"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="
                            claim.status_claim ==
                            'Disetujui Dibayarkan Sebagian'
                          "
                          style="color: #20cc86"
                        >
                          {{ claim.status_claim }}
                        </p>
                        <p
                          v-if="
                            claim.status_claim == 'Disetujui Dibayarkan Penuh'
                          "
                          style="color: #00b407"
                        >
                          {{ claim.status_claim }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </a-col>
            <a-col :lg="12">
              <div class="timeline" v-if="history_status.length > 0">
                <a-timeline mode="left">
                  <a-timeline-item
                    v-for="(item, i) in history_status"
                    :key="i"
                    :class="i != 0 ? '' : 'activehistory'"
                  >
                    <a-icon
                      slot="dot"
                      type="check-circle"
                      theme="filled"
                      v-if="i == 0"
                    />
                    <div>{{ item.created_at }}</div>

                    <div>
                      <span
                        v-if="item.status_baru == 'Menunggu Verifikasi'"
                        style="color: rgb(0, 196, 196)"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="item.status_baru == 'Hold'"
                        style="color: #333"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="item.status_baru == 'Pending'"
                        style="color: #ee5843"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="
                          item.status_baru ==
                          'Mohon Lengkapi Kekurangan Dokumen'
                        "
                        style="color: #ff9900"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="
                          item.status_baru == 'Sedang di-review oleh Asuransi'
                        "
                        style="color: #4c88ff"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="item.status_baru == 'Proses Banding ke Asuransi'"
                        style="color: #6464ff"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="item.status_baru == 'Tidak Dicover'"
                        style="color: #ea9999"
                      >
                        {{ item.status_baru }}
                      </span>
                      <span
                        v-if="
                          item.status_baru == 'Disetujui Dibayarkan Sebagian'
                        "
                        style="color: #20cc86"
                      >
                        {{ item.status_baru }}
                      </span>

                      <span
                        v-if="item.status_baru == 'Disetujui Dibayarkan Penuh'"
                        style="color: #00b407"
                      >
                        {{ item.status_baru }}
                      </span>

                      <div v-if="item.kekurangan_dokumen.length > 0">
                        <div style="font-size: 11px">Kekurangan Dokumen</div>
                        <ul>
                          <li
                            v-for="(items, ii) in item.kekurangan_dokumen"
                            :key="ii"
                            style="font-size: 11px"
                          >
                            {{ items }}
                          </li>
                        </ul>
                      </div>
                      <div v-if="item.file != null" style="margin-top: 10px">
                        <a :href="`${url + '' + item.file}`" target="_blank"
                          ><img
                            :src="require('@/assets/img/icon/fileeob.svg')" />
                          Download EOB <a-icon type="right"
                        /></a>
                      </div>
                    </div>
                  </a-timeline-item>
                  <!-- <a-timeline-item class="activehistory">
                  <a-icon slot="dot" type="check-circle" theme="filled" />
                  <div>Senin 27 April 2022 17:40</div>
                  <div>Klaim sedang diproses validasi diprovider asuransi</div>
                </a-timeline-item> -->
                  <!-- <a-timeline-item>
                  <div>Rabu 27 April 2022 17:40</div>
                  <div>
                    <div>Setuju di bayarkan Penuh</div>
                    <div>Kekurangan Dokumen:</div>
                    <ul>
                      <li>Kwitansi 1</li>
                      <li>Kwitansi 2</li>
                      <li>Kwitansi 3</li>
                    </ul>
                  </div>
                </a-timeline-item> -->
                  <!-- <a-timeline-item>
                  <div>Rabu 27 April 2022 17:40</div>
                  <div>Klaim sedang diproses validasi diprovider asuransi</div>
                </a-timeline-item> -->
                </a-timeline>
              </div>
              <a-empty v-else />
            </a-col>
          </a-row>
        </div>
      </a-skeleton>
    </a-modal>
    <ModalStatus ref="action" />
  </div>
</template>

<script>
import ModalStatus from "@/components/Modal/StatusClaim.vue";
import $axios from "@/utils/axios.js";
export default {
  components: {
    ModalStatus,
  },
  data() {
    return {
      modals: false,
      claim: {},
      history_status: [],
      loading: false,
      url: $axios.defaults.baseURL.replace("api", ""),
    };
  },
  methods: {
    ModalStatus() {
      this.$refs.action.Show();
    },
    handleOk() {
      this.modals = false;
    },
    handleCancel() {
      this.modals = false;
    },

    modal() {
      this.loading = true;
      this.modals = true;
      this.$store
        .dispatch("getHistoryStatusClaim", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;
          this.claim = response.data.data.claim;
          this.history_status = response.data.data.history_status;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
