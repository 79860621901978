var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalhistory"},[_c('a-modal',{attrs:{"title":"Status Pengajuan","centered":true,"width":"781px","footer":null},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.modals),callback:function ($$v) {_vm.modals=$$v},expression:"modals"}},[_c('a-skeleton',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"contentModalHistory"},[_c('a-row',{attrs:{"gutter":[5, 5]}},[_c('a-col',{attrs:{"lg":12}},[_c('div',{staticClass:"historytable"},[_c('table',[_c('thead',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('span',[_vm._v("Nomor")]),_c('p',[_vm._v(_vm._s(_vm.claim.no_claim))])])])]),_c('tbody',[_c('tr',[_c('td',[_c('span',[_vm._v("Produk")]),_c('p',[_vm._v(_vm._s(_vm.claim.produk))])]),_c('td',[_c('span',[_vm._v("Produk")]),_c('p',[_vm._v(_vm._s(_vm.claim.produk_detail))])])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('span',[_vm._v("Status "),_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.ModalStatus}},[_c('a-icon',{attrs:{"type":"info-circle"}})],1)],1),(_vm.claim.status_claim == 'Menunggu Verifikasi')?_c('p',{staticStyle:{"color":"rgb(0, 196, 196)"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(_vm.claim.status_claim == 'Hold')?_c('p',{staticStyle:{"color":"#333"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(_vm.claim.status_claim == 'Pending')?_c('p',{staticStyle:{"color":"#ee5843"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(
                          _vm.claim.status_claim ==
                          'Mohon Lengkapi Kekurangan Dokumen'
                        )?_c('p',{staticStyle:{"color":"#ff9900"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(
                          _vm.claim.status_claim ==
                          'Sedang di-review oleh Asuransi'
                        )?_c('p',{staticStyle:{"color":"#4c88ff"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(
                          _vm.claim.status_claim == 'Proses Banding ke Asuransi'
                        )?_c('p',{staticStyle:{"color":"#6464ff"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(_vm.claim.status_claim == 'Tidak Dicover')?_c('p',{staticStyle:{"color":"#ea9999"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(
                          _vm.claim.status_claim ==
                          'Disetujui Dibayarkan Sebagian'
                        )?_c('p',{staticStyle:{"color":"#20cc86"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e(),(
                          _vm.claim.status_claim == 'Disetujui Dibayarkan Penuh'
                        )?_c('p',{staticStyle:{"color":"#00b407"}},[_vm._v(" "+_vm._s(_vm.claim.status_claim)+" ")]):_vm._e()])])])])])]),_c('a-col',{attrs:{"lg":12}},[(_vm.history_status.length > 0)?_c('div',{staticClass:"timeline"},[_c('a-timeline',{attrs:{"mode":"left"}},_vm._l((_vm.history_status),function(item,i){return _c('a-timeline-item',{key:i,class:i != 0 ? '' : 'activehistory'},[(i == 0)?_c('a-icon',{attrs:{"slot":"dot","type":"check-circle","theme":"filled"},slot:"dot"}):_vm._e(),_c('div',[_vm._v(_vm._s(item.created_at))]),_c('div',[(item.status_baru == 'Menunggu Verifikasi')?_c('span',{staticStyle:{"color":"rgb(0, 196, 196)"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.status_baru == 'Hold')?_c('span',{staticStyle:{"color":"#333"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.status_baru == 'Pending')?_c('span',{staticStyle:{"color":"#ee5843"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(
                        item.status_baru ==
                        'Mohon Lengkapi Kekurangan Dokumen'
                      )?_c('span',{staticStyle:{"color":"#ff9900"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(
                        item.status_baru == 'Sedang di-review oleh Asuransi'
                      )?_c('span',{staticStyle:{"color":"#4c88ff"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.status_baru == 'Proses Banding ke Asuransi')?_c('span',{staticStyle:{"color":"#6464ff"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.status_baru == 'Tidak Dicover')?_c('span',{staticStyle:{"color":"#ea9999"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(
                        item.status_baru == 'Disetujui Dibayarkan Sebagian'
                      )?_c('span',{staticStyle:{"color":"#20cc86"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.status_baru == 'Disetujui Dibayarkan Penuh')?_c('span',{staticStyle:{"color":"#00b407"}},[_vm._v(" "+_vm._s(item.status_baru)+" ")]):_vm._e(),(item.kekurangan_dokumen.length > 0)?_c('div',[_c('div',{staticStyle:{"font-size":"11px"}},[_vm._v("Kekurangan Dokumen")]),_c('ul',_vm._l((item.kekurangan_dokumen),function(items,ii){return _c('li',{key:ii,staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(items)+" ")])}),0)]):_vm._e(),(item.file != null)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a',{attrs:{"href":`${_vm.url + '' + item.file}`,"target":"_blank"}},[_c('img',{attrs:{"src":require('@/assets/img/icon/fileeob.svg')}}),_vm._v(" Download EOB "),_c('a-icon',{attrs:{"type":"right"}})],1)]):_vm._e()])],1)}),1)],1):_c('a-empty')],1)],1)],1)])],1),_c('ModalStatus',{ref:"action"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }